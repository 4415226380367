import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@mui/material";
import Unit from "components/Unit/Unit.model";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomInput, CustomLabel, CustomSelect } from "./styled-components";
import SuccessMessage from "./SuccessMessage";
import useHubspot from "./useHubspot";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";
import { useLocation } from "react-router-dom";
import { useFetch } from "hooks/fetch/useFetch";
import { TESTEMAIL } from "api/api";
import { motion } from "framer-motion";
import axios from "axios";


const ContactForm = ({ grid, unit, campaignId, children }) => {
  
  const { i18n: { language }, } = useTranslation();

  const [personName, setPersonName] = useState([]);
  const [nationName, setNationName] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [describeName, setDescribeName] = useState([]);

  // const { submit, successMsg, errorMsg, inProgress, submitted } = useHubspot();
  const { register, handleSubmit, reset, formState: { errors, isValid }, } = useForm();
  const here = useLocation().pathname;
  const atUnit = here.includes("unit");
  const { pin, pid } = useGet(active_project) 

  const testLeadData = useFetch({ url: TESTEMAIL(language) });

  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const isDesktop = useMediaQuery('(min-width:1200px)');

  const unitNo = unit?.getValue("Projeknr") ? `${buttonsLabel?.filter( btn => btn?.UILabelProperty === 'Bolignr')[0]?.Label} ${unit.getValue("Projeknr")},` : "";
  const unitAddress = unit?.getAddress() ?? "";
  const unitId = unit?.getId();

  const homeUrl = window.location.origin;
  const unitUrl = unit?.getUrl(`${homeUrl}/${language}`) ?? "";
  
  
  const [checked, setChecked] = React.useState(false);

  const handleCheckbox = (event) => { setChecked(event.target.checked); };



  useEffect(()=> {
      testLeadData.mutate()
  }, [language])

  useEffect(() => {
    if (submitted) {
      reset();
      setChecked(false);
    }
  }, [submitted]);


  const handleFormSubmit = async (data) => {
    const DescribeIDMapping = {
      'Studerende': '1',
      'Student': '1',
      'Young profesional': '2',
      'Young Professional': '2',
      'Expat': '3',
      'Employee / Fuldtidsansat': '4',
      'B2B': '5',
      'Andet': '6',
      'Other': '6',
      'Personlig': '7',
      'Personal viewing': '7',
      'Digital': '8',
      'Personlig fremvisning': '7',
      'Digital presentation': '8',
      'Digital fremvisining': '8',
      'Traveling Professional': '9',
      'Digital viewing': '8',
      'Tidsbegrænset lejer': '11',
      'Temporary Stay': '11'
  };

  const utms = {
    utm_source: "google",
    utm_medium: "cpc",
    utm_campaign: "spring_sale",
    utm_content: "ad_variation_1"
  }

  let utmParam = {};
  for (let [key, value] of new URLSearchParams(location.search).entries()) {
    if(key === "utm_source" || key === "utm_medium" || key === "utm_campaign" || key === "utm_content") {
      utmParam[key] = value;
    }
  }
 
    const leadNationality = testLeadData?.D?.countries?.filter((c) => c?.name_dk === data?.nationality_id || c?.name_eng === data?.nationality_id)
    const leadCountry_code = testLeadData?.D?.countries?.filter((c) => c?.country_code === `+${data?.country_id.split("+")?.[1]}`)
     
        const payload = new URLSearchParams({
          Token: testLeadData?.D?.token,
          Hash: testLeadData?.D?.hash,
          Campaign: campaignId,
          B_ID: unitId,
          Fornavne: data.firstname,
          Efternavn: data.lastname,
          Email_privat: data.email,
          country_code: leadCountry_code?.[0]?.country_id,
          nationality_code: leadNationality?.[0]?.country_id,
          SELECTIONS: `${DescribeIDMapping[data?.describe_id] ?? ""}${data.viewing_id ? `,${data.viewing_id}` : ",0"}`,
          Telefon_mobil: data.phone,
          Comments: data.kommentar?.length ? data.kommentar : "",
          ...utmParam
        });
       // console.log(Object.fromEntries(payload))
      axios.post('https://show.estatetool.net/api2/post_leadtool/', payload, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then((data) => {
        setSubmitted(Object.keys(data || {}).length ? true : false);
      }).catch((err) => {
        enqueueSnackbar(language === "da" ? 'Noget gik galt. Prøv igen' : 'Something went wrong. Try again', { variant: 'error' });
      });
      testLeadData.mutate()
      setPersonName([]);
      setNationName([]);
      setDescribeName([])
      reset();
      setChecked(false);
  };

  const onSubmit = (data) => {
    handleFormSubmit(data);
  };

  // select dropdown configs
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;


  const MenuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: !grid ? "right" : "left" // Don't pass grid from contact drawer as this condition won't work
    },
    transformOrigin: {
      vertical: "top",
      horizontal: !grid ? "right" : "left"
    },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
        // width: dropdown_width
      },
    },
  };


// describe field options
  const describeOption = {
    'da': [
      "Studerende",
      "Young Professional",
      "Traveling Professional",
      "Expat",
      "B2B",
      "Tidsbegrænset lejer",
      "Andet"
    ],
    'en': [
      "Student",
      "Young Professional",
      "Traveling Professional",
      "Expat",
      "B2B",
      "Temporary Stay",
      "Other",
    ]
  }

  // handling the field which should have only number allowed
  const handleNumberInput = (event) => {
    return (event.key.charCodeAt() >= 48 && event.key.charCodeAt() <= 57) || event.key === 'Backspace' ? event.key : event.preventDefault();
  }

  return (
    <>
      {submitted ? (
          <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
             <SuccessMessage label={atUnit ? false : true} labelName = {"Signupmessage"} msg={ buttonsLabel?.filter( btn => btn.UILabelProperty === 'leadSuccessMsg')[0]?.Label } />
        </motion.div>
     
      ) : (
        <>
          <Box
            sx={{
              position: 'relative',
               color   : clr.sub_text_color
            }}
          >
            {children}
            {/* progress */}
            {/* {inProgress && (
              <Box
                sx={{
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  left: 0,
                  top: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 2,
                }}
              >

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 100,
                    height: 100,
                    backgroundColor: 'rgba(0,0,0,0.25)',
                  }}
                >
                  <CircularProgress />
                </Box>
              </Box>
            )} */}

            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                columnSpacing={3}
                rowSpacing={grid ? 2 : 1}
                sx={{ mb: 3 }}
              >
                <Grid item md={grid ? 6 : 12} xs={ !grid ? 12 : 10}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.firstname ? true : false}
                  >
                    <CustomLabel shrink>
                      { buttonsLabel?.filter( btn => btn.UILabelProperty === 'Formularfornavn')[0]?.Label } &nbsp;<span>*</span>
                    </CustomLabel>
                    <CustomInput
                      {...register('firstname', { required: true, validate: value => value.trim() !== "" })}
                    />
                  </FormControl>
                </Grid>

                <Grid item md={grid ? 6 : 12} xs={ !grid ? 12 : 10}>
                  <FormControl sx={{ mb: 3 }} fullWidth variant='filled'>
                    <CustomLabel shrink>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularEfternavn')[0]?.Label } &nbsp;<span>*</span></CustomLabel>
                    <CustomInput {...register('lastname', { required: true, validate: value => value.trim() !== "" })} />
                  </FormControl>
                </Grid>
                <Grid item md={grid ? 12 : 12} xs={ !grid ? 12 : 10}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.email ? true : false}
                  >
                    <CustomLabel shrink>
                      { buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularEmail')[0]?.Label } &nbsp;<span>*</span>
                    </CustomLabel>
                    <CustomInput {...register('email', { required: true, validate: value => value.trim() !== "" })} />
                  </FormControl>
                </Grid>

                <Grid item md={grid ? 6 : 12} xs={ !grid ? 12 : 10}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.country_id ? true : false}
                  >
                    <CustomLabel shrink>
                    { buttonsLabel?.filter( btn => btn.UILabelProperty === 'CountryCode')[0]?.Label } &nbsp;<span>*</span>
                    </CustomLabel>
                    
                    <CustomSelect
                        displayEmpty
                        value={personName}
                        onChange={(event) => setPersonName(event.target.value)}
                        onClose={(e) =>  setTimeout(() => {
                          document.activeElement.blur();
                        }, 0)}
                        input={<OutlinedInput {...register('country_id', { required: true, validate: value => value.trim() !== ""  })} placeholder="" />}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <CustomLabel shrink>
                            { buttonsLabel?.filter( btn => btn.UILabelProperty === 'CountryCodeLabel')[0]?.Label } 
                           </CustomLabel>
                          }

                          return selected;
                        }}
                        MenuProps={MenuProps}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value="">
                          <CustomLabel shrink>
                            { buttonsLabel?.filter( btn => btn.UILabelProperty === 'CountryCodeLabel')[0]?.Label } 
                          </CustomLabel>
                        </MenuItem>
                        {testLeadData?.D?.countries?.map((cnty) => (
                           <MenuItem
                           key={`${cnty.country_id} ${cnty.country_code}`}
                           value={`${language === "da" ? cnty?.name_dk : cnty?.name_eng } ${cnty.country_code}`}
                         >
                            {language === "da" ? cnty?.name_dk : cnty?.name_eng } {cnty.country_code} 
                         </MenuItem>
                        ))}
                      </CustomSelect>
                  </FormControl>
                </Grid>

                <Grid item md={grid ? 6 : 12} xs={ !grid ? 12 : 10}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.phone ? true : false}
                  >
                    <CustomLabel shrink>
                     { buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularTelefon')[0]?.Label } &nbsp;<span>*</span>
                    </CustomLabel>
                    <CustomInput type="tel" onKeyDown={handleNumberInput} {...register('phone', { required: true, validate: value => value.trim() !== "" })} />
                  </FormControl>
                </Grid>

                <Grid item md={grid ? 6 : 12} xs={ !grid ? 12 : 10}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.nationality_id ? true : false}
                  >
                    <CustomLabel shrink>
                     { language === 'da' ? 'Nationalitet' : 'Nationality' }
                     &nbsp;<span>*</span>
                    </CustomLabel>
                    
                    <CustomSelect
                        displayEmpty
                        value={nationName}
                        onChange={(event) => setNationName(event.target.value)}
                        onClose={(e) =>  setTimeout(() => {
                          document.activeElement.blur();
                        }, 0)}
                        input={<OutlinedInput {...register('nationality_id', { required: true, validate: value => value.trim() !== ""  })} placeholder="" />}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <CustomLabel shrink>
                            { buttonsLabel?.filter( btn => btn.UILabelProperty === 'chooseSelect2')[0]?.Label }
                           </CustomLabel>
                          }

                          return selected;
                        }}
                        MenuProps={MenuProps}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value="">
                          <CustomLabel shrink>
                            { buttonsLabel?.filter( btn => btn.UILabelProperty === 'chooseSelect2')[0]?.Label }
                          </CustomLabel>
                        </MenuItem>
                        {testLeadData?.D?.countries?.map((cnty) => (
                          <MenuItem
                            key={`${cnty.country_id}`}
                            value={`${language === "da" ? cnty?.name_dk : cnty?.name_eng }`}
                          >
                             {language === "da" ? cnty?.name_dk : cnty?.name_eng }
                          </MenuItem>
                        ))}
                      </CustomSelect>
                  </FormControl>
                </Grid>

                <Grid item md={grid ? 6 : 12} xs={ !grid ? 12 : 10}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.describe_id ? true : false}
                  >
                    <CustomLabel shrink>
                    { buttonsLabel?.filter( btn => btn.UILabelProperty === 'formSelectQuestion3')[0]?.Label } &nbsp;<span>*</span>
                    </CustomLabel>
                    
                    <CustomSelect
                        displayEmpty
                        value={describeName}
                        onChange={(event) => {
                          setDescribeName(event.target.value)
                        }}
                        onClose={(e) =>  setTimeout(() => {
                          document.activeElement.blur();
                        }, 0)}
                        input={<OutlinedInput {...register('describe_id', { required: true, validate: value => value.trim() !== ""  })} placeholder="" />}
                        renderValue={(selected, i) => {
                          const en = describeOption.en.findIndex((en) => en === selected)
                          const da = describeOption.da.findIndex((da) => da === selected)
                          if (selected.length === 0) {
                            return <CustomLabel shrink>
                            { buttonsLabel?.filter( btn => btn.UILabelProperty === 'chooseSelect3')[0]?.Label }
                           </CustomLabel>
                          }
                          return describeOption?.[language][en > -1 ? en : da];
                        }}
                        MenuProps={MenuProps}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value="">
                          <CustomLabel shrink>
                            { buttonsLabel?.filter( btn => btn.UILabelProperty === 'chooseSelect3')[0]?.Label }
                          </CustomLabel>
                        </MenuItem>
                        {describeOption?.[language]?.map((desc, index) => (
                          <MenuItem
                            key={`${desc} ${index}`}
                            value={desc}
                          >
                             { desc }
                          </MenuItem>
                        ))}
                      </CustomSelect>
                  </FormControl>
                </Grid>

                { atUnit &&
                  <Grid item md={12} xs={12}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.viewing_id ? true : false}
                  >
                    <CustomLabel shrink>  
                      { buttonsLabel?.filter( btn => btn.UILabelProperty === 'formRadioQuestion1')[0]?.Label } &nbsp;<span>*</span>
                    </CustomLabel>
                    
                    <RadioGroup
                        aria-labelledby="viewing_id"
                        defaultValue=""
                        name="viewing_id"
                       sx={{ "label > span" :  {color: "#fff" }}}
                      >
                        <FormControlLabel value="7" control={<Radio  {...register('viewing_id', { required: true})} sx={{ color: "#fff"}} />} label={ buttonsLabel?.filter( btn => btn.UILabelProperty === 'formRadioQuestion1option1')[0]?.Label } />
                        <FormControlLabel value="8" control={<Radio  {...register('viewing_id', { required: true})} sx={{ color: "#fff"}} />} label={ buttonsLabel?.filter( btn => btn.UILabelProperty === 'formRadioQuestion1option2')[0]?.Label } />
                      </RadioGroup>
                  </FormControl>
                </Grid>
                }
                  { !atUnit &&
                <Grid item md={12} xs={12}>
                    <FormControl sx={{ mb: 3 }} fullWidth variant='filled'>
                      <CustomLabel shrink>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularKommentar')[0]?.Label }</CustomLabel>
                      <CustomInput
                        multiline
                        rows={4}
                        {...register('kommentar')}
                      />
                    </FormControl>
                  </Grid>
              }
                {/* <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl sx={{ mb: 3 }} fullWidth variant='filled'>
                    <CustomLabel shrink>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularAdresse')[0]?.Label }</CustomLabel>
                    <CustomInput {...register('address')} />
                  </FormControl>
                </Grid> */}

                {/* <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl sx={{ mb: 3 }} fullWidth variant='filled'>
                    <CustomLabel shrink>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularPostnummer')[0]?.Label }</CustomLabel>
                    <CustomInput {...register('zip')} />
                  </FormControl>
                </Grid> */}


              </Grid>

              <Box sx={{ mb: 3 }}>
                <CustomLabel>
                  { buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularBetingelser')[0]?.Label } &nbsp;<span>*</span>
                </CustomLabel>
                <FormControlLabel
                  sx={{
                    mr: 0,
                    "span":{    color: clr?.alternative_condition },
                  
                    "a":{color: `${clr?.alternative_condition} !important` },
                  }}
                 
                  control={
                    <Checkbox
                      name='_value'
                      checked={checked}
                      onChange={handleCheckbox}s
                    />
                  }
                  label={
                    <Box >
                      <div  dangerouslySetInnerHTML={{ __html: buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularBetingelserTekst')[0]?.Label }}>
                      </div>
                    </Box>
                  }
                />
              </Box>

              <Button
                type='submit'
                disabled={!checked || !isValid}
                variant = {"contained"}
                size    = "large"
                sx      = {{
                  textAlign: grid ? 'left' : 'center',
                  borderRadius: '6px',
                  color       : clr?.buttons_text,
                  border      : 'none',
                  padding     : "11px 24px 11px 24px",
                }}          
              >
                <span className = "paragraph1-bold" style = {{fontWeight : 600 }}>
                  { buttonsLabel?.filter( btn => btn.UILabelProperty === 'signUp')[0]?.Label }
                </span>
              </Button>
            </form>
          </Box>
        </>
      )}
    </>
  )
};

ContactForm.propTypes = {
  children: PropTypes.node,
  grid: PropTypes.bool,
  unit: PropTypes.instanceOf(Unit),
};

export default ContactForm;
