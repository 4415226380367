import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%; 
  height: 416px;
  min-width: 756px; 
  // min-height: 416px; 
  // padding: 0px 0px 20px 20px;
  // border : 1px solid white;
  border-radius: 0px 0px 0px 6px;
  opacity: 1; 
  position: relative; 
  margin: 0 auto; 
  box-sizing: border-box; 
`;

const Image = styled.img`
  width: 100%; 
  height: 100%; 
  //  object-fit: cover; 
     border-radius: 0px 0px 0px 6px;
`;

function Mapbox () {
  return (
    <>
    <Container>
      <Image src="/estatetool/logos/mapbox.png" alt="Mapbox Logo" />
    </Container>
    </>
  );
}

export default Mapbox;
