import styled   from 'styled-components/macro'
import { data } from 'components/Favorites/Card/Card.data'
import { form } from 'components/Favorites/Card/Card.data'
import { BREAK_ } from 'constants'

export const Text = ({j,childRef,maxHeight,atUnit, unit, cardType, mode}) => {
  const type = 'A'
  
  return (
    <UnitInfo maxHeight={maxHeight} ref={(el) => {
      if(childRef?.current) childRef.current[j] = el
    }} atUnit={atUnit} className='h4' cardType={cardType} >
      {
        data[type].map( (line, i) => {
          const d = form(unit,line);
          return <Line mode={mode} className= {`${line?.key?.toString() === "1" ? "h4" : "paragraph1-light"}`} key={line.key}>{ d }</Line>
        })
      }
    </UnitInfo>
  )
}

const Line = styled.div`
  // white-space: nowrap;
  // text-overflow: ellipsis;
  overflow: ${({mode}) => mode === 'card' ? "visible" : "hidden" };
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  :nth-child(1) { font-weight: 600; }
  text-wrap: wrap;
`

export const UnitInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
  min-height: ${({ maxHeight, cardType }) => maxHeight ? maxHeight + 'px' : cardType === "grid" ? "121px" : "137px"};
  padding-top: ${({cardType}) => "8px" }};
  padding-left: ${({cardType}) => cardType === "grid"? "24px" : "16px"}};
  padding-bottom: ${({cardType, atUnit}) => "8px" }};
  padding-right: ${({cardType}) => "24px" }};

   ${ BREAK_.md_dn } { 
    min-height: ${({cardType, atUnit, maxHeight}) => cardType === "grid" ? atUnit ? ( maxHeight ? maxHeight + 'px' : "130px" ) : ( maxHeight ? maxHeight + 'px' : "143px" ) : ( maxHeight ? maxHeight + 'px' : "134px" )}};
   }
`