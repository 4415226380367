import { Box, Typography, } from "@mui/material";
import ContactForm from "components/Contact/ContactForm";
import PropTypes from "prop-types";
import React from "react";
import Unit from "./Unit.model";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";
import { useLocation } from "react-router-dom";

function SignUpForm({ unit }) {
  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const here         = useLocation().pathname
  const atUnit       = here.includes('unit')

  return (
    <Box id="unit_page_contact_form" sx={{ pt: 7, pb: { md: 5, xs: 0 }, px: { md: 4, xs: 2.5 }, }} >
      <Box sx={{ maxWidth: 525, mx: "auto", }} >
        <ContactForm campaignId={'a01891bb96de7ceab1b5d86de0bc8ddd'} grid unit={unit}>
          <Box sx={{ mb: 4, }} >
          <Box sx={{ fontWeight: 700, lineHeight: 1.4, }} > 
              {
                atUnit ?
                buttonsLabel?.filter( btn => btn.UILabelProperty ===  "footerHeading1")[0]?.Label
                :
                buttonsLabel?.filter( btn => btn.UILabelProperty ===  "contactDrawerHeading1")[0]?.Label
              }
            </Box>
            <br />
            <Box sx={{ fontWeight: 400, lineHeight: 1.4, fontSize: 15}} >
            { buttonsLabel?.filter( btn => btn.UILabelProperty ===  (atUnit ? 'BookShowingHeading' : 'SidefodformularUnderoverskrift'))[0]?.Label }
            </Box>
          </Box>
        </ContactForm>
      </Box>
    </Box>
  );
}

SignUpForm.propTypes = {
  unit: PropTypes.instanceOf(Unit).isRequired,
};

export default SignUpForm;
