import { atom, useAtom } from "jotai";

const isOpenLeadDrawer = atom(false);
const LeadDrawerUnit = atom(null);

const useLeadDrawer = () => {
  const [isOpen, setIsOpen] = useAtom(isOpenLeadDrawer);
  const [unit, setUnit] = useAtom(LeadDrawerUnit);

  const toggle = () => {
    if (isOpen) {
      // set null if closed.
      setUnit(null);
    }

    setIsOpen(!isOpen);
  };

  const open = (unit) => {
    setUnit(unit);
    setIsOpen(true);
  };

  return { toggle, open, isOpen, unit };
};

export { useLeadDrawer };
