import { BREAK_ } from 'constants'
import { BREAK } from 'constants'
import styled from 'styled-components/macro'

export const Main = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fit, 450px);
  place-content: center;
  background:transparent;
  white-space: nowrap;
  overflow: hidden;
  textOverflow: ellipsis;
  flex-wrap: wrap;
    ${BREAK_.md_dn} {
    grid-template-columns: repeat(auto-fit, ${({atUnit}) => atUnit ? "300px" : "361px"});
  }
`

export const Item = styled.div`
  width: 450px;
  min-height: 461px;
  cursor: pointer;
  padding: 0px 1px 0 1px;
  /* border:1px solid black; */
  border-radius: 6px;
  border-top: 8px solid ${({ color }) => color};
  background-color: ${({ color }) => (() => color + '22')()};
  :hover { background-color: ${({ color }) => (() => color + '33')()}; }
  ${BREAK_.md_dn} {
    min-height: ${({atUnit}) => atUnit ? "404px" : "423px"};
    overflow: hidden;
    width: ${({atUnit}) => atUnit ? "300px" : "361px"};
  }
`