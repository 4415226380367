import { Box, Button, Checkbox, Divider, Drawer, FormControl, FormControlLabel, Grid, IconButton, MenuItem, OutlinedInput, Radio, RadioGroup, useMediaQuery } from "@mui/material";
import { Icon } from "components/Icons";
import React, { useEffect, useState } from "react";
import ContactForm from "./ContactForm";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";
import { useLeadDrawer } from "./useLeadDrawer";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { TESTEMAIL } from "api/api";
import { CustomInput, CustomLabel, CustomSelect } from "./styled-components";
import { useFetch } from "hooks/fetch/useFetch";
import axios from "axios";
import { motion } from "framer-motion";
import SuccessMessage from "./SuccessMessage";
import { useOpenHouseData } from "components/OpenHouseBanner/OpenHouseBanner.Data";

const LeadDrawer = () => {
  const { ready, MM, MMM, Sitename, Time, Description, Address } =
  useOpenHouseData();
  
  const [submitted, setSubmitted] = useState(false);
  
  const [checked, setChecked] = useState(false);
  const [personName, setPersonName] = useState([]);
  const [nationName, setNationName] = useState([]);
  const [describeName, setDescribeName] = useState([]);
  const grid = false
  const { isOpen, toggle, unit } = useLeadDrawer();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { i18n: { language } } = useTranslation();
  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
  const contactData  = getFocus(_project, 'ContactProperties')?.[pin]?.[pid] ?? []

  const testLeadData = useFetch({ url: TESTEMAIL(language) });

  const unitNo = unit?.getValue("Projeknr") ? `${buttonsLabel?.find(btn => btn?.UILabelProperty === 'Bolignr')?.Label} ${unit.getValue("Projeknr")},` : "";
  const unitId = unit?.getId();
  const unitAddress = unit?.getAddress() ?? "";

  const { register, handleSubmit, reset, formState: { errors, isValid }, } = useForm({ mode: "onChange", });
  const homeUrl = 'https://estatetool.findapartmentaarhus.unity-living.com';
  const unitUrl = unit?.getUrl(`${homeUrl}/${language}`) ?? "";
  const onSubmit = (data) => {
    handleFormSubmit(data);
  };

  const handleFormSubmit = async (data) => {
    const DescribeIDMapping = {
      'Studerende': '1',
      'Student': '1',
      'Young profesional': '2',
      'Young Professional': '2',
      'Expat': '3',
      'Employee / Fuldtidsansat': '4',
      'B2B': '5',
      'Andet': '6',
      'Other': '6',
      'Personlig': '7',
      'Personal viewing': '7',
      'Digital': '8',
      'Personlig fremvisning': '7',
      'Digital presentation': '8',
      'Digital fremvisining': '8',
      'Traveling Professional': '9',
      'Digital viewing': '8',
      'Tidsbegrænset lejer': '11',
      'Temporary Stay': '11'
  };

  const utms = {
    utm_source: "google",
    utm_medium: "cpc",
    utm_campaign: "spring_sale",
    utm_content: "ad_variation_1"
  }

  let utmParam = {};
  for (let [key, value] of new URLSearchParams(location.search).entries()) {
    if(key === "utm_source" || key === "utm_medium" || key === "utm_campaign" || key === "utm_content") {
      utmParam[key] = value;
    }
  }

  const DANISH_CAMPAIGN = "b1051b467cc6c92f10922a80fc340ef2"
  const ENGLISH_CAMPAIGN = "a12aa96ce33d7ccb05506f62b34f4869"

  const leadNationality = testLeadData?.D?.countries?.filter((c) => c?.name_dk === data?.nationality_id || c?.name_eng === data?.nationality_id)
  const leadCountry_code = testLeadData?.D?.countries?.filter((c) => c?.country_code === `+${data?.country_id.split("+")?.[1]}`)

      const payload = new URLSearchParams({
        Token: testLeadData?.D?.token,
        Hash: testLeadData?.D?.hash,
        Campaign: "9ab5fe5aa77030cf30f9df9b6754d8ff",
        B_ID: unitId,
        Fornavne: data.firstname,
        Efternavn: data.lastname,
        Email_privat: data.email,
        country_code: leadCountry_code?.[0]?.country_id,
        nationality_code: leadNationality?.[0]?.country_id,
        SELECTIONS: `${DescribeIDMapping[data?.describe_id]},0`, 
        Telefon_mobil: data.phone,
        Comments: "",
        ...utmParam
      });
      // console.log(Object.fromEntries(payload))

      // enqueueSnackbar(variant ? language === "da" ? "Emailen er blevet sendt" : "The email has been sent" : language === "da" ? 'Fejl ved indsendelse af formular' : 'Error submitting form', { variant: variant ? 'success' : 'error' });


      axios.post('https://show.estatetool.net/api2/post_leadtool/', payload, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then((data) => {
        setSubmitted(Object.keys(data || {}).length ? true : false);
      }).catch((err) => {
        enqueueSnackbar(language === "da" ? 'Noget gik galt. Prøv igen' : 'Something went wrong. Try again', { variant: 'error' });
      });

      testLeadData.mutate()
      setPersonName([]);
      setNationName([]);
      setDescribeName([])
      reset();
      setChecked(false);
  };

  
  useEffect(()=> {
    testLeadData.mutate()
  }, [language])

  useEffect(()=> {
    setSubmitted(false)
  }, [isOpen])

  const handleNumberInput = (event) => {
    return (event.key.charCodeAt() >= 48 && event.key.charCodeAt() <= 57) || event.key === 'Backspace' ? event.key : event.preventDefault();
  };

  
  const handleChange = (event) => {
    setPersonName(event.target.value);
  };


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: !grid ? "right" : "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: !grid ? "right" : "left"
    },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
      },
    },
  };

  const describeOption = {
    'da': [
      "Studerende",
      "Young Professional",
      "Traveling Professional",
      "Expat",
      "B2B",
      "Tidsbegrænset lejer",
      "Andet"
    ],
    'en': [
      "Student",
      "Young Professional",
      "Traveling Professional",
      "Expat",
      "B2B",
      "Temporary Stay",
      "Other",
    ]
  }
  
  const handleCheckbox = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <Drawer
      anchor={isMobile ? "bottom" : "right"}
      open={isOpen}
      onClose={() => toggle()}
      PaperProps={{ sx: { scrollbarWidth: "none", "&::-webkit-scrollbar": { display: "none" }, color: "secondary.contastText", backgroundColor: "secondary.main", }, }}
    >
      <>
      {submitted ? (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          style={{ height: "100%", display: "flex", alignItems: "center" }}
        >
             <SuccessMessage label={false} msg={ language === 'da' ? `<div style={{ fontFamily: "Cerebri sans" }}><p>Tak for din tilmelding til åbent hus.<br/>  Vi glæder os til at se dig d. ${MM} ${MMM}.</p></div>` : `<div style={{ fontFamily: "Cerebri sans !important" }}><p>Thank you for registering for the open house.<br/> We look forward to seeing you on ${MM} ${MMM}.</p></div>`} width={422}/>
        </motion.div>
      ) :
      <>  
      <Box sx={{ width: isMobile ? "auto" : 422, position: "relative", pt: 4, pb: 4, px: 3, lineHeight: 1.3, }} >
        <IconButton
          onClick={toggle}
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
          }}
        >
          <Icon
            icon="close"
            sx={{
              fontSize: 18,
            }}
          />
        </IconButton>
        <Box sx={{ mt: 3, lineHeight: 1.5, }} >
          <Box sx={{ fontWeight: 700, mb: 1 }}><h2>
            {/* {language === "da" ? "Bestil fremvisning" : "Book viewing"} */}
            { buttonsLabel?.filter( btn => btn.UILabelProperty === 'OpenHouseBtnLabel')[0]?.Label }
            </h2></Box>
          <Box sx={{ mb: 2 }}>
            <p>
              {/* {language === "da" ? "Udfyld venligst formularen, og en af de rigtige agenter vil kontakte dig via telefon eller e-mail så hurtigt som muligt" : "Please fill out the form and one of out real agents will contact you by phone or email as soon as possible" } */}
              { buttonsLabel?.filter( btn => btn.UILabelProperty === 'OpenHouseHeading')[0]?.Label } { MM } { MMM }
            </p>
          </Box>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>

              <Grid
                container
                columnSpacing={3}
                rowSpacing={grid ? 2 : 1}
                sx={{ mb: 3 }}
              >
                <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.firstname ? true : false}
                  >
                    <CustomLabel shrink>
                      { buttonsLabel?.filter( btn => btn.UILabelProperty === 'Formularfornavn')[0]?.Label } &nbsp;<span>*</span>
                    </CustomLabel>
                    <CustomInput
                      {...register('firstname', { required: true, validate: value => value.trim() !== "" })}
                    />
                  </FormControl>
                </Grid>

                <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl sx={{ mb: 3 }} fullWidth variant='filled'>
                    <CustomLabel shrink>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularEfternavn')[0]?.Label } &nbsp;<span>*</span></CustomLabel>
                    <CustomInput {...register('lastname', { required: true, validate: value => value.trim() !== ""  })} />
                  </FormControl>
                </Grid>

                <Grid item md={grid ? 12 : 12} xs={12}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.email ? true : false}
                  >
                    <CustomLabel shrink>
                      { buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularEmail')[0]?.Label } &nbsp;<span>*</span>
                    </CustomLabel>
                    <CustomInput {...register('email', { required: true, validate: value => value.trim() !== ""  })} />
                  </FormControl>
                </Grid>

                <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.country_id ? true : false}
                  >
                    <CustomLabel shrink>
                    { buttonsLabel?.filter( btn => btn.UILabelProperty === 'CountryCode')[0]?.Label } &nbsp;<span>*</span>
                    </CustomLabel>
                    
                    <CustomSelect
                        displayEmpty
                        value={personName}
                        onChange={handleChange}
                        onClose={(e) =>  setTimeout(() => {
                          document.activeElement.blur();
                        }, 0)}
                        input={<OutlinedInput {...register('country_id', { required: true, validate: value => value.trim() !== ""  })} placeholder="" />}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <CustomLabel shrink>
                            { buttonsLabel?.filter( btn => btn.UILabelProperty === 'CountryCodeLabel')[0]?.Label } 
                           </CustomLabel>
                          }

                          return selected;
                        }}
                        MenuProps={MenuProps}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value="">
                          <CustomLabel shrink>
                            { buttonsLabel?.filter( btn => btn.UILabelProperty === 'CountryCodeLabel')[0]?.Label } 
                          </CustomLabel>
                        </MenuItem>
                        {testLeadData?.D?.countries?.map((cnty) => (
                           <MenuItem
                           key={`${cnty.country_id} ${cnty.country_code}`}
                           value={`${language === "da" ? cnty?.name_dk : cnty?.name_eng } ${cnty.country_code}`}
                         >
                            {language === "da" ? cnty?.name_dk : cnty?.name_eng } {cnty.country_code} 
                         </MenuItem>
                        ))}
                      </CustomSelect>
                  </FormControl>
                </Grid>
                
                <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.phone ? true : false}
                  >
                    <CustomLabel shrink>
                     { buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularTelefon')[0]?.Label } &nbsp;<span>*</span>
                    </CustomLabel>
                    <CustomInput type="tel" onKeyDown={handleNumberInput} {...register('phone', { required: true, validate: value => value.trim() !== ""  })} />
                  </FormControl>
                </Grid>

                <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.nationality_id ? true : false}
                  >
                    <CustomLabel shrink>
                    {/* { buttonsLabel?.filter( btn => btn.UILabelProperty === 'CountryCode')[0]?.Label }
                     */}
                     
                     { language === 'da' ? 'Nationalitet' : 'Nationality' }
                     &nbsp;<span>*</span>
                    </CustomLabel>
                    
                    <CustomSelect
                        displayEmpty
                        value={nationName}
                        onChange={(event) => setNationName(event.target.value)}
                        onClose={(e) =>  setTimeout(() => {
                          document.activeElement.blur();
                        }, 0)}
                        input={<OutlinedInput {...register('nationality_id', { required: true, validate: value => value.trim() !== ""  })} placeholder="" />}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <CustomLabel shrink>
                            {/* { buttonsLabel?.filter( btn => btn.UILabelProperty === 'CountryCodeLabel')[0]?.Label }  */}
                            { language === 'da' ? 'Vælg nationalitet' : 'Select nationality' }
                           </CustomLabel>
                          }

                          return selected;
                        }}
                        MenuProps={MenuProps}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value="">
                          <CustomLabel shrink>
                            {/* { buttonsLabel?.filter( btn => btn.UILabelProperty === 'CountryCodeLabel')[0]?.Label }  */}
                            { language === 'da' ? 'Vælg nationalitet' : 'Select nationality' }
                          </CustomLabel>
                        </MenuItem>
                        {testLeadData?.D?.countries?.map((cnty) => (
                          <MenuItem
                            key={`${cnty.country_id}`}
                            value={`${language === "da" ? cnty?.name_dk : cnty?.name_eng }`}
                          >
                             {language === "da" ? cnty?.name_dk : cnty?.name_eng }
                          </MenuItem>
                        ))}
                      </CustomSelect>
                  </FormControl>
                </Grid>
                
                <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.describe_id ? true : false}
                  >
                    <CustomLabel shrink>
                    {/* { buttonsLabel?.filter( btn => btn.UILabelProperty === 'CountryCode')[0]?.Label }
                     */}
                     
                     { language === 'da' ? 'Hvad beskriver dig bedst?' : 'What describe you best?' } &nbsp;<span>*</span>
                    </CustomLabel>
                    
                    <CustomSelect
                        displayEmpty
                        value={describeName}
                        onChange={(event) => {
                          setDescribeName(event.target.value)
                        }}
                        onClose={(e) =>  setTimeout(() => {
                          document.activeElement.blur();
                        }, 0)}
                        input={<OutlinedInput {...register('describe_id', { required: true, validate: value => value.trim() !== ""  })} placeholder="" />}
                        renderValue={(selected, i) => {
                          const en = describeOption.en.findIndex((en) => en === selected)
                          const da = describeOption.da.findIndex((da) => da === selected)
                          if (selected.length === 0) {
                            return <CustomLabel shrink>
                            {/* { buttonsLabel?.filter( btn => btn.UILabelProperty === 'CountryCodeLabel')[0]?.Label }  */}
                            { language === 'da' ? 'Vælg venligst' : 'Please choose' }
                           </CustomLabel>
                          }
                          return describeOption?.[language][en > -1 ? en : da];
                        }}
                        MenuProps={MenuProps}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value="">
                          <CustomLabel shrink>
                            {/* { buttonsLabel?.filter( btn => btn.UILabelProperty === 'CountryCodeLabel')[0]?.Label }  */}
                            { language === 'da' ? 'Vælg venligst' : 'Please choose' }
                          </CustomLabel>
                        </MenuItem>
                        {describeOption?.[language]?.map((desc, index) => (
                          <MenuItem
                            key={`${desc} ${index}`}
                            value={desc}
                          >
                             { desc }
                          </MenuItem>
                        ))}
                      </CustomSelect>
                  </FormControl>
                </Grid>

                {/* <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.viewing_id ? true : false}
                  >
                    <CustomLabel shrink>                   
                   { language === 'da' ? 'Hvordan vil du have din fremvisning?' : 'How do you want your viewing to be?' } &nbsp;<span>*</span>
                    </CustomLabel>
                    
                    <RadioGroup
                        aria-labelledby="viewing_id"
                        defaultValue=""
                        name="viewing_id"
                       sx={{ "label > span" :  {color: "#fff" }}}
                      >
                        <FormControlLabel value="7" control={<Radio  {...register('viewing_id', { required: true})} sx={{ color: "#fff"}} />} label={language === "da" ? "Personlig fremvisning" : "Personal viewing"} />
                        <FormControlLabel value="8" control={<Radio  {...register('viewing_id', { required: true})} sx={{ color: "#fff"}} />} label={language === "da" ? "Digital fremvisining" : "Digital viewing"} />
                      </RadioGroup>
                  </FormControl>
                </Grid> */}

              </Grid>

              <Box sx={{ mb: 3 }}>
                <CustomLabel>
                  { buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularBetingelser')[0]?.Label } &nbsp;<span>*</span>
                </CustomLabel>
                <FormControlLabel
                  sx={{
                    mr: 0,
                    mt: 3
                  }}
                  control={
                    <Checkbox
                      name='_value'
                      checked={checked}
                      onChange={handleCheckbox}
                      sx={{color:clr?.primary_text}}
                    />
                  }
                  label={
                    <Box sx={{color:clr?.primary_text}}>
                      {/* <div dangerouslySetInnerHTML={{ __html: buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularBetingelserTekst')[0]?.Label }}>
                      </div> */}
                      {
                        language === "da" ?
                        <>
                          Jeg accepterer{' '}
                            <strong>
                              <a 
                                style={{color:clr?.primary_text}}
                                target='_blank'
                                href='https://estatetool.dk/terms/'>
                                betingelser
                              </a>
                            </strong>{' '}
                            og at estatetool. må kontakte mig med information og nyheder vedr. systemet. Jeg kan til enhver tid tilbagekalde mit samtykke.
                        </>
                        : 
                        <>
                         I accept the {' '}
                          <strong>
                            <a 
                              style={{color:clr?.primary_text}}
                              target='_blank'
                              href='https://estatetool.dk/terms/'>
                              terms and conditions
                            </a>
                          </strong>{' '}
                          and that estatetool. must contact me with information and news regarding the system. I can revoke my consent at any time.
                        </>
                      }
                    </Box>
                  }
                />
              </Box>

              <Box
                sx={{
                  textAlign: grid ? 'left' : 'center',
                }}
              >
                <Button
                type='submit'
                disabled={!checked || !isValid}
                variant = {"contained"}
                size    = "large"
                sx      = {{
                  textAlign: grid ? 'left' : 'center',
                  borderRadius: '6px',
                  color       : clr?.buttons_text,
                  border      : 'none',
                  padding     : "11px 24px 11px 24px",
                }}          
              >
                <span className = "paragraph1-bold" style = {{fontWeight : 600 }}>
                  { buttonsLabel?.filter( btn => btn.UILabelProperty === 'signUp')[0]?.Label }
                </span>
              </Button>
                {/* <Box
                  sx={{
                    my: 3,
                    color: 'success.main',
                  }}
                >
                  <Box sx={{ color: 'success.main' }}>{successMsg}</Box>
                  <Box sx={{ color: 'error.main' }}>{errorMsg}</Box>
                </Box> */}
              </Box>
              {/* <div style={{height:50}}/> */}
            </form>
      </Box>
      </>
    }
      </>
    </Drawer>
  );
};

export default LeadDrawer;


{/* <SuccessMessage label={false} msg={language === "da" ? <p>Vi har registreret dit ønske om at bestille en fremvisning.<br/> Du vil blive kontaktet af vores mægler for at aftale nærmere.</p> : <p>We have registered your wish to order a screening. <br />You will be contacted by our broker to make further arrangements</p>} width={422}/> */}