import styled from 'styled-components'
import { BREAK_ } from 'constants'
import { motion } from 'framer-motion'

export const Legend = styled(motion.div)`
  max-width: 100vw;
  width: ${({ cssStyle }) => cssStyle?.width ? cssStyle.width : '260px'};
  // background-color: ${({ cssStyle }) => cssStyle?.backgroundColor ? cssStyle.backgroundColor : '#ffffff'};
  background-color: ${({ }) => `${clr.primary}bf`};
  padding: ${({ cssStyle }) => cssStyle?.padding ? cssStyle.padding : '16px'};
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  user-select: none;
  bottom: 57px;
  left: ${({ cssStyle }) => cssStyle?.left ? cssStyle.left : '17px'};
  position: ${({ cssStyle }) => cssStyle?.position ? cssStyle.position : 'fixed'};
  border: ${({ cssStyle }) => cssStyle?.border ? cssStyle.border : `1px solid ${clr.primary}`};
  border-radius:8px;
  ${ BREAK_.md_up } {
    left:${({ type }) => type == "mono" ? "400px" : "604px"};
    bottom:50px;
    height: max-content;
    transition: 0.5s linear;
  }
`
export const Dot = styled.div`
  border-radius: 50%;
  min-width: 8px;
  min-height: 8px;
  /* border:1px solid black; */
`
export const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding:0 5px;
  color:${({clr})=> clr?.primary_text};
  /* border:1px solid black; */
`
export const Txt = styled.div`
  line-height:1;
  white-space:nowrap;
   color:${({clr})=> clr?.primary_text};
  font-size:14px;
  ${ BREAK_.md_up } {
    font-size: 16px;
  }
  /* border:1px solid black; */
`