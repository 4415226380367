import { Box, Button, IconButton, Modal } from "@mui/material";
import { Icon } from "components/Icons";
import PropTypes from "prop-types";
import React from "react";
import Unit from "./Unit.model";
import { Slider } from "components/MaximizedGallery/MaximizedGallery.Slider";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1444,
  maxWidth: { md: "80%", xs: "100%" },
  boxShadow: "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
  outline: "none"
};

const FloorplansModalButton = ({ unit }) => {
  const floorplanUrl = unit.getFloorplanUrl();
  const [open, setOpen] = React.useState(false);

  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button
        onClick={handleOpen}
        sx={{
          height: '40px',
          flexDirection: "row",
          gap: '5px',
          backgroundColor: `${clr?.buttons_text}bf`,
          borderRadius: "6px",
          color: "#000000",
          py: "8px",
          px: "16px",
          lineHeight: 1,
          fontWeight: 400,
          fontSize: 14,
          transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out;",
          "&:hover": {
            backgroundColor: `${clr?.buttons_text}bf`,
            color: "#000000",
            transform: "scale(1.1)",
            opacity: 1,
            boxShadow: "none",
          },
        }}
      >
        {/* <span className="icon-hover-effect " style={{display:"flex", alignItems:"center"}}> */}
        <Icon icon="floorplan" style={{ marginRight: "8px" }} size={"xl"} />
       <span className="paragraph1-regular">
          {
            buttonsLabel?.filter(
              (btn) => btn.UILabelProperty === "plan"
            )[0]?.Label
          }
         </span>
         {/* </span> */}
      </Button>
      <Modal open={open} onClose={handleClose} sx={{
        " .MuiBackdrop-root": {
          backgroundColor: `${clr.primary}bf`
        },
      }}>
        <Box sx={style}>
          <IconButton
            onClick={handleClose}
            size="small"
            sx={{
              zIndex: "1301",
              position: "absolute",
              right: 0,
              top: -40,
              color: "#fff",
            }}
          >
            <Icon icon="close" fontSize="inherit" />
          </IconButton>
          <Box
            component="img"
            src={floorplanUrl}
            alt=""
            sx={{
              display: "block",
              width: '100%'
            }}
          />
          {/* <Slider  images={floorplanUrl}/> */}
        </Box>
      </Modal>
    </div>
  );
};

FloorplansModalButton.propTypes = {
  unit: PropTypes.instanceOf(Unit).isRequired,
};

export { FloorplansModalButton };
