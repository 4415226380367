import { useState, useEffect } from "react";
import { useAtom, get, useGet, getFocus } from "state/jotai";
import { _fullscreen, active_project } from "state/store.global";
import { Fullscreen } from "components/Fullscreen/Fullscreen";
import { MaximizedGallery } from "components/MaximizedGallery/MaximizedGallery";
import { StyledContainer } from "./Gallery.style";
import { StyledTabsContainer } from "./Gallery.style";
import { StyledTabButton } from "./Gallery.style";
import { StyledGridContainer } from "./Gallery.style";
import { StyledGrid } from "./Gallery.style";
import { StyledGridItem } from "./Gallery.style";
import { StyledImg } from "./Gallery.style";
import { _project } from "state/store.projects";
import { PoweredBy } from "components/PoweredBy/PoweredBy";
import { Box, Button, IconButton, Modal } from "@mui/material";
import { Icon } from "components/Icons";
import { Slider } from '../MaximizedGallery/MaximizedGallery.Slider'

export const Gallery = () => {
  const gallery = get(_project, "gallery");
  const photos = gallery?.Data?.ListOfPhotos ?? [];
  const tags = gallery?.Tags ?? [];
  
  const [, setIsFullscr] = useAtom(_fullscreen);
  const [activeTag, setActiveTag] = useState(null);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1444,
    maxWidth: { md: "64%", xs: "100%" },
    boxShadow: "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
    outline: "none"
  };
  const [open, setOpen] = useState(false);
  const handleOpen = (i) => { setOpen(true); setSelectedIndex(i); };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setSelectedPhotos(
      photos.reduce(function (filtered, photo, i) {
        if (activeTag === null || photo.Tags.includes(activeTag)) {
          filtered.push({
            key: i,
            url: photo.Url,
            thumbnail: photo.UrlThumbnail,
            description: photo.Description,
          });
        }
        return filtered;
      }, [])
    );
  }, [activeTag, gallery]);

  const handleClickImage = (i) => {
    setSelectedIndex(i);
    setIsFullscr(true);
  };

  return (
    <>
      {/* tags */}
      <StyledTabsContainer>
        <StyledTabButton
          clr={clr}
          active={activeTag === null}
          onClick={() => setActiveTag(null)}
        >
          {
            buttonsLabel?.filter((btn) => btn.UILabelProperty === "Alle")[0]
              ?.Label
          }
        </StyledTabButton>
        {tags.map((tag, i) => {
          return (
            <StyledTabButton
              key={i}
              clr={clr}
              active={activeTag === tag}
              onClick={() => setActiveTag(tag)}
            >
              {
                buttonsLabel?.filter((btn) => btn.UILabelProperty === tag)[0]
                  ?.Label
              }
            </StyledTabButton>
          );
        })}
      </StyledTabsContainer>
      <StyledContainer>
        {/* grid / images */}
        <StyledGridContainer>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "calc(100vh - 271px)",
              justifyContent: "space-between",
            }}
          >
            <StyledGrid>
              {selectedPhotos.map((photo, i) => {
                return (
                  <StyledGridItem key={photo.key}>
                    <StyledImg
                      src={photo.thumbnail}
                      alt={photo.description}
                      onClick={() => handleOpen(i)}
                    />
                  </StyledGridItem>
                );
              })}
            </StyledGrid>
            <PoweredBy />
          </Box>
          {/* <Box sx={{height: { md: '76px', xs: '87px' }}}/> */}
        </StyledGridContainer>
      </StyledContainer>
      {/* <Fullscreen>
        <MaximizedGallery initial={selectedIndex} images={selectedPhotos} />
      </Fullscreen> */}
      <Modal open={open} onClose={handleClose} sx={{
        " .MuiBackdrop-root": {
          backgroundColor: `${clr.primary}bf`
        },
      }}>
        <Box sx={style}>
          <IconButton
            onClick={handleClose}
            size="small"
            sx={{
              zIndex: "1301",
              position: "absolute",
              right: 0,
              top: -40,
              color: "#fff",
            }}
          >
            <Icon icon="close" fontSize="inherit" />
          </IconButton>
          <Slider initial={selectedIndex} images={selectedPhotos} />
        </Box>
      </Modal>
    </>
  );
};
