import { useAtom } from "jotai";
import { useEffect, useCallback } from "react";
import pLimit from 'p-limit';
import { cacheImg, priorityQueueAtom } from "state/store.global";

// Create a p-limit instance with a concurrency limit
const limit = pLimit(30); // Adjust the limit as needed


// const fetchPromises = {}

export const useCachedImages = (shouldFetch, imageUrl, priority = 1) => {
 

  const [cacheImgState, setCacheImg] = useAtom(cacheImg);
  const [priorityQueue, setPriorityQueue] = useAtom(priorityQueueAtom);
  const [fetchPromises, setFetchPromises] = useAtom(priorityQueueAtom);

  const loadImage = useCallback(async () => {

    if (!imageUrl || cacheImgState[imageUrl]) return cacheImgState[imageUrl]

    // If there's already a fetch in progress for this URL, use that promise
    if (fetchPromises.hasOwnProperty(imageUrl)) {
      return fetchPromises[imageUrl];
    }

    // Local queue copy to avoid frequent state updates
    let queueCopy = [...priorityQueue];

    // Check if the image is already in the priority queue
    const isAlreadyInQueue = queueCopy.some(item => item.imageUrl === imageUrl);
    if (!isAlreadyInQueue) {
      // Add the fetch request to the priority queue
      queueCopy.push({ imageUrl, priority });
      queueCopy.sort((a, b) => a.priority - b.priority); // Sort the queue here
      setPriorityQueue(queueCopy); // Update state after sorting
    }



    // Process the priority queue
    while (queueCopy.length > 0) {
      const { imageUrl: nextImageUrl } = queueCopy[0]; // Get the first item from the queue

      // Create a fetch promise and store it in the atom
      const fetchPromise = limit(async () => {
        try {
          const response = await fetch(nextImageUrl);
          if (!response.ok) throw new Error('Network response was not ok');
          const blob = await response.blob();
          const blobUrl = URL.createObjectURL(blob);

          // Update cache
          setCacheImg(prevCache => ({
            ...prevCache,
            [nextImageUrl]: blobUrl,
          }));

   

          // Remove the promise and the processed URL from the queue
          let fetchPromiseCopy = { ...fetchPromises }
          delete fetchPromiseCopy[nextImageUrl];
          setFetchPromises(fetchPromiseCopy)
          queueCopy = queueCopy.filter(item => item.imageUrl !== nextImageUrl); // Update local copy
          setPriorityQueue(queueCopy); // Update state with filtered queue
        } catch (error) {
          let fetchPromiseCopy = { ...fetchPromises }
          delete fetchPromiseCopy[nextImageUrl];
          setFetchPromises(fetchPromiseCopy)
        }
      });
    //   if(nextImageUrl === 'https://show-estatetool.b-cdn.net/media/bolig/d6435731062a787569574dc0bbde16b8_D.jpg') {
    //     console.log(nextImageUrl, )
    // }
    fetchPromises[nextImageUrl] = fetchPromise
      await fetchPromise; // Wait for the promise to resolve
    }
  }, [imageUrl]);

  useEffect(() => {
    if (shouldFetch) {
      loadImage();
    }

    return () => {
      // Optionally clear promises on cleanup
    };
  }, [loadImage, shouldFetch]);

  return cacheImgState[imageUrl] || null;
};
