import React, { useRef } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "assets/Icons_";
import { active_project } from "state/store.global";
import { getFocus, useGet } from "state/jotai";
import { _project } from "state/store.projects";
import { BREAK_ } from "constants";
import { darken } from "polished";


// Styled component for ETStyledButton

export const ETStyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 8px;
 box-sizing: border-box;
  height:44px ;
  
  border-radius: ${({ size, borderRadius }) => (borderRadius ? borderRadius : size === "sm" ? "4px" : "8px")};
  padding: ${({ padding }) => (padding ? padding : "12px 40px")};
  line-height: 1;
  user-select: none;
  white-space: nowrap;

  ${BREAK_.md_up} {
    height: 44px;
    width: ${({ width }) => (width ? width : '185px')};
  }

  /* Background color logic */
  background-color: ${({ variant, clr, pin, type, isIsometry, isMobile, backgroundColor }) => {
    if (type === "poly" && pin === 0 && isIsometry) {
      return isMobile && variant === "primary"
        ? `${clr?.primary}`
        : `${clr?.buttons_text}`
    }
    if (type === "mono" && pin === 0 && isIsometry) {
      return isMobile && variant === "secondary"
        ? `${clr?.buttons_text}`
        : `${clr?.primary}`
    }
    if (type === "poly" && pin !== 0 && isIsometry) {
      return isMobile && variant === "secondary"
        ? `${clr?.buttons_text}`
        :`${clr?.primary}`
    }
    if(backgroundColor) return backgroundColor;
    return variant === "primary" ? `${clr?.primary}` : `${clr?.buttons_text}`;
  }};
  
  color: ${({ backgroundColor, variant }) =>
    backgroundColor === "gray" || variant === "secondary"
      ? "#000000"
      : "#ffffff"};

  transition: background-color 0.1s ease-in, border 0.1s ease-in,
    box-shadow 0.1s ease-in, color 0.1s ease-in;

  border: ${({ variant }) =>
    variant === "primary" ? "none" : "1px solid #D1D1D1"};

  &:hover {
    background-color: ${({ variant, clr, pin, type, isIsometry, isMobile, backgroundColor }) => {
      if (type === "poly" && pin === 0 && isIsometry) {
        return isMobile && variant === "primary"
          ? darken(0.04, clr?.primary)
          : clr?.buttons_text;
      }
      if (type === "mono" && pin === 0 && isIsometry) {
        return isMobile && variant === "secondary"
          ? clr?.buttons_text
          : darken(0.04, clr?.primary);
      }
      if(backgroundColor) return darken(0.04, backgroundColor);
      return variant === "primary" ? darken(0.04, clr?.primary) : clr?.buttons_text;
    }};
  
     
    border: ${({ clr, variant }) =>
      variant === "primary" ? "none" : "1px solid #D1D1D1"};
  }

  &:focus {
    background-color: ${({ variant, clr, pin, type, isIsometry, isMobile, isStatus, backgroundColor }) => {
      if (isStatus) {
        return null; // Logic to handle status
      }
      if (type === "poly" && pin === 0 && isIsometry) {
        return isMobile && variant === "primary"
          ? darken(0.04, clr?.primary)
          : clr?.buttons_text;
      }
      if (type === "mono" && pin === 0 && isIsometry) {
        return isMobile && variant === "secondary"
          ? clr?.buttons_text
          : darken(0.04, clr?.primary);
      }
      if(backgroundColor) return darken(0.04, backgroundColor);
      return variant === "primary" ? darken(0.04, clr?.primary) : clr?.buttons_text;

    }};
   
    box-shadow: ${({ variant, clr, isStatus }) => {
    if (variant === "primary" && !isStatus) {
      return `0 0 0 2.5px ${clr?.primary}`; // Primary focus style
    }
    if (variant === "secondary" && !isStatus) {
      return `0 0 0 1.5px #D1D1D1`; // Secondary focus style
    }
    return "none";
  }};
//  filter: ${({  isStatus })=>{ isStatus ? null : "brightness(85%)"} }
   
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
// box-shadow: ${({ variant, clr, isStatus }) => {
//     if (variant === "primary" && !isStatus) {
//       return `0 0 0 2.5px ${clr?.primary}`; // Primary focus style
//     }
//     if (variant === "secondary" && !isStatus) {
//       return `0 0 0 1.5px #D1D1D1`; // Secondary focus style
//     }
//     return "none";
//   }};

export default function EusButton({
  width,
  backgroundColor,
  onClick = () => {},
  buttonText,
  otherText,
  icon,
  fontSize,
  rotate,
  isFaIcon,
  iconColor,
  disabled,
  padding,
  iconSize,
  type,
  variant,
  height,
  iconAlign,
  size,
  iconName,
  clr,
  borderRadius,
  buttonlabel,
  hoverTextColor, // New prop for hover text color
  hoverIconColor, // New prop for hover icon color
  focusTextColor, // New prop for focus text color
  focusIconColor, // New prop for focus icon color
}) {
  const buttonRef = useRef(null);
  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  const handleClick = () => {
    if (buttonRef?.current) {
      buttonRef?.current?.blur();
    }
  };

  return (
    <ETStyledButton
      ref={buttonRef}
      width={width}
      disabled={disabled}
      onClick={() => {
        onClick();
        // handleClick();
      }}
      borderRadius={borderRadius}
      variant={variant}
      clr={clr}
      padding={padding}
      type={type }
      size={size}
      backgroundColor={backgroundColor}
      height={height}
      fontSize={fontSize}
      hoverTextColor={hoverTextColor} // Pass hover text color
      hoverIconColor={hoverIconColor} // Pass hover icon color
      focusTextColor={focusTextColor} // Pass focus text color
      focusIconColor={focusIconColor} // Pass focus icon color
    >
      {icon && iconAlign === "left" && (
        <Icon
          color={iconColor ?iconColor :  clr?.primary_text} // make sure clr is passed correctly
          icon={iconName}
          isFaIcon={isFaIcon }
          size={isFaIcon ? iconSize ? iconSize : "lg" : size} // for FontAwesome
        />
      )}
      {buttonlabel ? (
        <span className="eus-button-2">
          {
            buttonsLabel?.filter(
              (btn) => btn?.UILabelProperty === `${buttonText}`
            )[0]?.Label
          }
        </span>
      ) : (
        <span className="eus-button-2">{otherText}</span>
      )}
      {icon && iconAlign === "right" && (
        <Icon
          color={iconColor ?iconColor :  clr?.primary_text} // make sure clr is passed correctly
          icon={iconName}
          isFaIcon={isFaIcon }
          size={isFaIcon ? iconSize ? iconSize : "lg" : size} // for FontAwesome
        />
      )}
    </ETStyledButton>
  );
}

EusButton.defaultProps = {
  disabled: false,
  type: "button",
  iconAlign: "left",
};
