import { Box } from "@mui/material";
import React from "react";
import {
  Buttons,
  DrawerCTA,
  Gallery,
  Highlights,
  Price,
  TopDrawer,
  useActiveUnit,
} from ".";
import { useUnitHighlight } from "./hooks";
import OpenHouse from "./OpenHouse";
import { getFocus, useAtom, useGet } from "state/jotai";
import { get, label } from "components/Favorites/Favorites.Utilities";
// import { drawer_animation_done } from "state/store.global";
import { _drawer, active_project, drawer_animation_done} from 'state/store.global'
import { PoweredBy } from "components/PoweredBy/PoweredBy";
import ReservationButton from "./ReservationButton";
import DropdownProperties from "./DropdownProperties";
import { useBreak } from "hooks/useBreak";
import { Scrollbar } from "react-scrollbars-custom";
import { useLocation } from "react-router-dom";
import { _bootstrap } from "state/store.bootstrap";


function UnitDrawer() {
  const here = useLocation().pathname;
  const { pin, pid }         = useGet(active_project);
  const unit = useActiveUnit();
  const highlights = useUnitHighlight(unit);
  const unitId = parseInt(unit.getId());
  const status = unit.getStatus();
  const address = unit.getAddress();
  const moveindate = unit.getMoveInDate();
  const townAndZipcode = unit.getTownZipCode();

  const isRent = unit.isRent();
  const price = unit.getPrice();
  const properties = unit.getProperties();
  
  const filteredProperties = properties.filter(
    (property) => property?.order !== 0
  );
  
  const isDrawerAnimating = useAtom(drawer_animation_done)[0];
  const isMobile = useBreak('md_dn')
  const drawer   = useAtom(_drawer)[0] 
  const isDetail = drawer.detail
  const thumbnails = isDetail ? unit.getThumb() : [];
  const images = isDetail ? unit.getImages() : [];
  const unitNo = unit?.data?.Data?.NPV_Projektnr?.Value


  const projectType = getFocus(_bootstrap, "type");
  const today = new Date(moveindate);

  // Specify Danish as the locale and customize options
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const danishFormattedDate = today.toLocaleDateString('da-DK', options);
   
  return (
    <Box
      sx={{
        width: "100%",
        height:isMobile?"calc(100% - 73px)": "calc(100% - 157px)",
        backgroundColor: "#ffffff",
        overflowY: "auto",
        scrollbarWidth: "none",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderLeft:`1px solid ${clr?.primary}`,
      }}
    >
        <Scrollbar trackYProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return <span {...restProps} ref={elementRef}
            className="trackY" />;
          },
        }}>
      <Box>
        <Box sx={{position:"relative", zIndex:2}}>
        <TopDrawer
          availableDate={danishFormattedDate}
          address={`${address}, ${townAndZipcode}`}
          statusText={status.label}
          statusId={status.id}
          backgroundColor={status.color}
          textColor="#ffffff"
          unitNo ={unitNo}
        />
        </Box>
        <Box sx={{position:"relative",top:"0", zIndex:1, background: 'white'}}>
        <Gallery images={images} thumbImg={images} UnitDrawer height = {isMobile ? ' 100%' : '273px'} />

        </Box>
        <Buttons unitId={unitId} address={ (address || townAndZipcode ) ? `${address}, ${townAndZipcode}` : ""} rent={price} highlights={highlights} />

        <OpenHouse />

        <Box sx={{  mt: { md: 3.5, xs: 2 } }}>
            <Highlights items={highlights} />

          <Box
            sx={{
              mt: { md: 3, xs: 2 },
              textAlign: "center",
            }}
          >
            <Price
              label={
                isRent
                  ? `${get(unit.data, "LejelejlighedsText")}`
                  : `${label(unit.data, "Kontantpris")}`
              }
              value={price}
            />
          </Box>
          <ReservationButton unit={unit}/>
          <DropdownProperties groups={filteredProperties}/>
          {/* <DrawerCTA unit={unit} /> */}
        </Box>
      </Box>
      <PoweredBy padding={"16px 0 16px 0"} />
        </Scrollbar>
    </Box>
  );
}

export default UnitDrawer;
