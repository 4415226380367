import { useEffect, useState } from "react";
import { Backdrop } from "styles/styles.backdrop";
import { getFocus, useAtom, useGet } from "state/jotai";
import {
  Box,
  InputBase,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  RadioGroup,
  Radio,
} from "@mui/material";
import Button from "@mui/material/Button";
import { STATICS } from "api/api";
import { Icon } from "assets/Icons_";
import styled from "styled-components";
import {
  active_project,
  vis_calculate_benefit,
  vis_benefit_extended,
} from "state/store.global";
import { housing_benefit } from "state/store.global";
import { gross_income } from "state/store.global";
import {
  Container,
} from "./HousingBenefit.style";
import "./HousingBenefit.css";
import { useHousingBenefit } from "./HousingBenefit.hook";
import { _project } from "state/store.projects";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { CustomLabel } from "components/Contact/styled-components";
import CircularProgress from '@mui/material/CircularProgress';
import { useActiveUnit } from "components/Unit";
import loader from 'json-animation/loader.json'
import Lottie from "lottie-react";

export const HousingBenefitExtended = () => {
  const [pensionistType, setPensionistType] = useState("")
  const [step, setStep] = useState(1)
  const unit = useActiveUnit();
  
  const area = unit.getArea();
  const price = unit.getPrice();
  const rooms = unit.getNoOfRooms();
  const[loading, setLoading]=useState(false);
  const [beneExtended, setBeneExtended] = useAtom(vis_benefit_extended);
  const [pensioner, setPensioner] = useState(false);

  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  const output = useHousingBenefit();
  const href  = 'https://www.boligstoette.dk/'
  const link  = <a href = {href} style={{color:"#00000050"}}>https://www.boligstoette.dk/</a>

  const icon = {
    icon: "close6",
    color: "#fff",
    wrap: { position: "absolute", top: 20, right: 20 },
    size: "20",
    click: () => setBeneExtended(false),
  };
  const openPensioner = (state, isPensionist) => {
    setPensioner(state)
    if(isPensionist === "Nej") setPensionistType("")
  }

  useEffect(() => {
    if(step === 2) {
      setTimeout(() => {
        setStep(3);
      }, 1000);
    }
  }, [step]);


  return (
    <>
      <Backdrop
        onClick={() => {
          setBeneExtended(false);
        }}
      />
      <Container>
        <Box sx={{ position: "relative" }}>
          <ImgContainer>
            <img src={`${STATICS}generic-modal-image.png`} alt="" />
          </ImgContainer>
          <Box
            sx={{
              position: "absolute",
              bottom: "16px",
              left: "16px",
              color: "white",
            }}
          >
            <ImgLabel><p className="h4">Beregn din boligstøtte for bolig 41</p></ImgLabel>
          </Box>
        </Box>
        <Icon {...icon} />
        {
          step === 1 ?
          <Box sx={{padding:"20px", 
            // height:"510px", 
            overflow:{xs:'scroll'}}}>
            <H2> Bolig information </H2>
            <div>
              <Grid container spacing={1} sx={{marginTop:"0px"}}>
                <Grid item xs={6} md={3}>
                  <FormControl sx={{ mb: 3 }} fullWidth variant="filled">
                    <CustomLabel shrink>Antal værelser</CustomLabel>
                    <CustomInput value={rooms} disabled/>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormControl sx={{ mb: 3 }} fullWidth variant="filled">
                    <CustomLabel shrink>Antal m2</CustomLabel>
                    <CustomInput value={area} disabled/>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={6}>
                  <FormControl sx={{ mb: 3 }} fullWidth variant="filled">
                    <CustomLabel shrink>Husleje pr. md. </CustomLabel>
                    <CustomInput value={`${price}/md.`} disabled/>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormControl sx={{ mb: 3 }} fullWidth variant="filled">
                    <CustomLabel shrink>Antal voksne</CustomLabel>
                    <CustomInput/>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormControl sx={{ mb: 3 }} fullWidth variant="filled">
                    <CustomLabel shrink>Antal børn under 18 år</CustomLabel>
                    <CustomInput/>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={6}>
                  <FormControl sx={{ mb: 3 }} fullWidth variant="filled">
                    <CustomLabel shrink>
                      Hustandens samlede indkomst pr. md. (før skat)
                    </CustomLabel>
                    <CustomInput/>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            <Box  sx={{
              // height:{md:'170px',xs:'auto'}
              pb: 3
              }}>
              <div>
                <H2>Er du pensionist?</H2>
                <FormGroup sx={{ flexDirection: "row" }}>
                 <RadioGroup
                      aria-labelledby="pensionist-radio"
                      name="pensionist-radio"
                    >
                      <FormControlLabel onClick={()=>openPensioner(true, "Ja")} value="Ja" control={<Radio />} label="Ja" />
                      <FormControlLabel onClick={()=>openPensioner(false, "Nej")} value="Nej" control={<Radio />} label="Nej" />
                  </RadioGroup>
                </FormGroup>
              </div>
              { pensioner &&
                <div >
                  <H2>Hvilken type pensionist?</H2>
                  <FormGroup sx={{ flexDirection: "row" }}>
                  <RadioGroup
                      aria-labelledby="pensionist-type"
                      name="pensionist-type"
                      sx={{ display: "flex", flexDirection: "row" }}
                    >
                    {
                      pensionType.map((pt, ptIndex) => {
                        return (
                          <CustomFormControlLabel key={ptIndex} onClick={()=>openPensioner(true)} value={pt} control={<Radio onChange={()=> setPensionistType(pt)} />} label={pt} />
                        )
                      })
                    }
                    </RadioGroup>
                  </FormGroup>
                </div>
              }
              </Box>
              <Box sx={{display:"flex", justifyContent:"center"}}>
                <Button
                  variant="contained"
                  disabled={!pensionistType.length && pensioner}
                  endIcon={<ArrowRightAltIcon />}
                  sx={{
                    padding: "11px 24px",
                    borderRadius: "6px",
                    fontSize: '16px',
                    fontWeight: 600,
                    backgroundColor: clr.buttons,
                    color: clr.buttons_text,
                    "&:hover": {backgroundColor: clr.buttons }
                  }}
                  onClick={() => { setLoading(true); setStep(2) }}
                >
                  BEREGN BOLIGSTØTTE
                </Button>
              </Box>
            <Stack direction="row" spacing="1px" justifyContent="center"></Stack>
          </Box>
          : step === 2 ?
          <Box>
            <Box sx={{ display: 'flex', flexDirection:"column",justifyContent:'center',alignItems:'center',height: {xs: "400px", md: '510px'} }}>
              <LoaderContiner>
                <Lottie animationData={loader} loop={true} />
              </LoaderContiner>
              <p>Venligst vent mens vi beregner din boligstøtte</p>
            </Box>
          </Box>
          : step === 3 ?
              <Box sx={{padding:"24px"}}>
                <Box sx={{paddingBottom:"15px"}}>
                  <h3 style={{ margin: 0 }} className="h3">Din boligstøtte</h3>
                  </Box>
                  <Box sx={{display:"flex",flexDirection:"column",padding:"15px 0"}}>
                    <h3 className="h3" style={{ color: clr.buttons, margin: 0 }}>1.279 kr./md.</h3>
                  </Box>
                  <Box sx={{ height:{xs: '200px' , md:"280px"}}}>
                    <p style={{color:"#00000080", fontSize:"12px", lineHeight:"16px"}}>Den boligstøtte beregning du kan se her, er vejledende og vi giver ingen garanti i forhold til den 
                      endelige boligstøtte du måtte kunne få. Brug eventuelt {link} som har en beregner der stilles til rådighed af Udbetaling Danmark.</p>
                  </Box>
                  <Box sx={{display:"flex", justifyContent:"center"}}>
                  <Button
                    variant="contained"
                    endIcon={<ArrowRightAltIcon />}
                    sx={{
                      padding: "11px 24px",
                      borderRadius: "6px",
                      fontSize: '16px',
                      fontWeight: 600,
                      backgroundColor: clr.buttons,
                      color: clr.buttons_text,
                      "&:hover": {backgroundColor: clr.buttons }
                    }}
                    onClick={() => setBeneExtended(false)}
                  >
                    Gem din boligstøtte
                  </Button>
                </Box>
              </Box>
            : <></>
        }
        
      </Container>
    </>
  );
};

export const LoaderContiner = styled.div`
  width: 144px;
  height: 144px;
`

export const H2 = styled.h2`
  font-size:18px;
  font-weight:700;
  margin: 10px 0;
`;
export const Line = styled.div`
  min-height: 1px;
  max-height: 1px;
  width: 100%;
  background: lightgray;
`;
// export const CustomGrid = styled(Grid)
export const CustomFormControlLabel = styled(FormControlLabel)(() => ({
  fontSize: "14px"
}));

const CustomInput = styled(InputBase)(() => ({
  // "label + &": {
  //   marginTop: theme.spacing(1),
  // },
  "& .MuiInputBase-input.Mui-disabled": {
    border: "1px solid #D1D1D1",
    color: "#000000a8",
    WebkitTextFillColor: "#000000a8",
  },

  "& .MuiInputBase-input": {
    textAlign      : "left",
    position       : "relative",
    backgroundColor: "#ffffff10",
    border         : "1px solid transparent",
    borderColor    : "#00000055",
    fontSize       : 16,
    width          : "100%",
    padding        : "10px",
    margin         : "5px 0",
    transition     : "border-color 0.3s",
    borderRadius: "6px",
    "&:focus"      : {
      borderColor: "#000000",
    },
  },
  // "&.Mui-error .MuiInputBase-input": {
  //   borderColor: theme.palette.error.main,
  // },
}));


const ImgContainer = styled.div`
 img {
   width: 100%;
 }
`

const ImgLabel = styled.div`
  border-radius: 6px;
  color: #000;
  background: #fcfbfbbd;
  padding: 8px;
`


// pension type

const pensionType = [
  "Folkepensionist",
  "Førtidspensionist (før 2023)",
  "Førtidspensionist (efter 2023)"
]